export const getParameter = (pName: any) => {
    let url = window.location.href,
        start = url.indexOf('?') + 1,
        paras = {};
    if (start !== 0) {
        let queryString = url.substring(start),
            paraNames = queryString.split('&'),
            arr = [],
            i = 0;
        for (; i < paraNames.length; i++) {
            arr = paraNames[i].split('=');
            if (i === paraNames.length - 1) {
                let sIndex = arr[1].indexOf('#');
                if (sIndex !== -1) {
                    arr[1] = arr[1].substring(0, sIndex);
                }
            }
            paras[arr[0]] = arr[1];
        }
    }
    return decodeURIComponent(paras[pName] || '');
};